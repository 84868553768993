import {useEffect, useState} from "react";
import ApiService from "../services/ApiService";
import {useAuth} from "../AuthContext";
import {useMantineTheme} from "@mantine/core";


const PlanDisplay = ({}: any) => {
    const { isAuthenticated, profile } = useAuth();
    // const [subscriptionStatus, setSubscriptionStatus] = useState(null)
    // const theme = useMantineTheme();
    //
    // useEffect(() => {
    //     ApiService.getSubscriptionStatus().then((result) => {
    //         setSubscriptionStatus(result);
    //     })
    // }, []);
    //
    // if (isAuthenticated && subscriptionStatus) {
    //     let interval_name = ''
    //     if (subscriptionStatus['billing_cycle'] == 'month') {
    //         interval_name = 'Monthly '
    //     }
    //     else if (subscriptionStatus['billing_cycle'] == 'year') {
    //         interval_name = 'Yearly '
    //     }
    //
    //     return <span>{interval_name}{subscriptionStatus['current_plan']} plan</span>
    // }
    // else {
    //     return <></>
    // }
    return (
        <><span>Credits: <b>369</b></span><span>Subscription: <b>Creator</b></span></>
        // <><span>Credits: <b>{profile?.accountBalance}</b></span><span>Subscription: <b>{profile?.plan}</b></span></>
    )
}

export default PlanDisplay
