import React, {useState} from "react";
import {
    Burger,
    Image,
    Group,
    Flex,
    ActionIcon,
    AppShell,
    useMantineColorScheme,
    useComputedColorScheme,
    useMantineTheme,
    Button, Modal
} from "@mantine/core";
import {useMediaQuery} from "@mantine/hooks";
import {IconMoon, IconSun} from "@tabler/icons-react";
import {useAuth} from '../AuthContext'; // Adjust the import according to your file structure
import Login from './Login';
import PlanDisplay from "./PlanDisplay"; // Adjust the import according to your file structure

const Header = ({toggle, opened}: any) => {
    const {isAuthenticated, profile} = useAuth();
    const theme = useMantineTheme();
    const {setColorScheme} = useMantineColorScheme();
    const computedColorScheme = useComputedColorScheme('light');

    const logo = computedColorScheme === "dark" ? "polyq_ai_logo_light.png" : "polyq_ai_logo_dark.png";
    const borderColor = computedColorScheme === "dark" ? "#555" : "#E3E4E6";
    const backgroundColor = computedColorScheme === "dark" ? theme.colors.accent?.[2] : theme.colors.gray?.[1];
    const iconColor = computedColorScheme === "dark" ? theme.colors.gray?.[4] : theme.colors.brand?.[4];
    const buttonColor = computedColorScheme === "dark" ? theme.colors.brand?.[4] : theme.colors.primary?.[6];
    const buttonTextColor = computedColorScheme === "dark" ? theme.colors.gray?.[9] : theme.colors.gray?.[0];
    const Icon = computedColorScheme === "dark" ? IconSun : IconMoon;
    const [loginModalOpened, setLoginModalOpened] = useState(false);
    const [isSignup, setIsSignup] = useState(false)
    const toggleColorScheme = () => {
        setColorScheme(computedColorScheme === 'dark' ? 'light' : 'dark');
    };

    const hideExtraInfo = useMediaQuery('(max-width: 775px)');
    const hideBigLogo = useMediaQuery('(max-width: 600px)');
    const removeWordWelcome = useMediaQuery('(max-width: 450px)');

    return (
        <>
            <AppShell.Header>
                <Flex justify="space-between" align="center"
                      style={{padding: hideBigLogo ? '10px 20px 10px 0px' : '10px 20px'}}>
                    <Group wrap='nowrap' style={{marginRight: isAuthenticated ? '20px' : "10px", marginLeft: "10px"}}>
                        <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm"/>
                        <Image src={"logo64.png"} alt="Logo" height={35} fit="contain"/>
                        {
                            hideBigLogo ?
                                <></>
                                :
                                <Image src={logo} alt="Logo" height={35} fit="contain"/>
                        }
                    </Group>

                    <Group wrap='nowrap'>
                        {isAuthenticated ? (
                            <>
                                {
                                    hideExtraInfo ?
                                        <></>
                                        :
                                        <PlanDisplay/>
                                }
                                {
                                    profile?.firstName ?
                                        <span>{`${removeWordWelcome ? '' : 'Welcome '}${profile?.firstName}`}</span>
                                        :
                                        // <span>{`${removeWordWelcome ? '' : 'Welcome '}${profile?.id}`}</span>
                                        <span>Welcome demo@polyq.ai</span>

                                }
                            </>
                        ) : (
                            <>
                                <Button
                                    radius={"xl"}
                                    variant="outline"
                                    style={{borderColor: buttonColor, color: buttonColor}}
                                    onClick={() => {
                                        setIsSignup(true)
                                        setLoginModalOpened(true)
                                    }}
                                >
                                    Sign Up
                                </Button>
                                <Button
                                    radius={"xl"}
                                    variant="filled"
                                    style={{backgroundColor: buttonColor, color: buttonTextColor}}
                                    onClick={() => {
                                        setIsSignup(false)
                                        setLoginModalOpened(true)
                                    }}
                                >
                                    Login
                                </Button>
                            </>
                        )}
                        <ActionIcon radius={"md"} size={"lg"} variant={"link"} onClick={toggleColorScheme} style={{
                            borderColor: borderColor,
                            backgroundColor: backgroundColor
                        }}>
                            <Icon color={iconColor}/>
                        </ActionIcon>
                    </Group>
                </Flex>
            </AppShell.Header>
            <Modal
                opened={loginModalOpened}
                onClose={() => setLoginModalOpened(false)}
                title="Login"
                centered
            >
                <Login onClose={() => setLoginModalOpened(false)} initialSignup={isSignup}/>
            </Modal>
        </>
    );
};

export default Header;
